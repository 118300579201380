<template>
	<div class="wrapper">
		<div class="searchbox">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item label="店铺名称">
					<el-input v-model="formInline.showname" clearable placeholder="店铺名称"></el-input>
				</el-form-item>
				<el-form-item label="负责人">
					<el-input v-model="formInline.gridman" clearable placeholder="负责人"></el-input>
				</el-form-item>
				<el-form-item label="巡防地址">
					<el-input v-model="formInline.address" clearable placeholder="巡防地址"></el-input>
				</el-form-item>
				<el-form-item label="审核状态">
					<el-select v-model="formInline.status" clearable placeholder="审核状态">
						<el-option label="提交审核" value="0"></el-option>
						<el-option label="审核通过" value="1"></el-option>
						<el-option label="审核未通过" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="search"><i class="el-icon-search"></i> 查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="contbox">
			<el-table :data="tableData" :header-cell-style="{  fontSize: '14px', color: '#282828'}"
				:row-style="{ fontSize: '12px', color: '#6a6a6a'}" border style="width: 100%">
				<el-table-column align="center" prop="create_time" label="创建时间">
				</el-table-column>
				<el-table-column align="center" prop="createname" label="提交人">
				</el-table-column>
				<el-table-column align="center" prop="showname" label="店铺名称">
				</el-table-column>
				<el-table-column align="center" prop="ninetypename" label="场所类型">
				</el-table-column>
				<el-table-column align="center" prop="name" label="负责人">
				</el-table-column>
				<el-table-column align="center" prop="period" label="巡防周期"
					:formatter="(row) => formatterStatus(row.period, periodOptions)">
				</el-table-column>
				<el-table-column align="center" prop="newperiod" label="修改后巡防周期"
					:formatter="(row) => formatterStatus(row.newperiod, periodOptions)">
				</el-table-column>
				<el-table-column align="center" prop="address" label="巡防地址">
				</el-table-column>
				<el-table-column align="center" label="审核状态">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 0">提交审核</span>
						<span v-if="scope.row.status == 1" style="color: green;">审核通过</span>
						<span v-if="scope.row.status == 2" style="color: red;">审核未通过</span>
					</template>
				</el-table-column>
				<el-table-column align="center" fixed="right" label="操作" width="180">
					<template slot-scope="scope">
						<el-button :disabled="scope.row.status != 0 || scope.row.alarm_status == 2" @click="dealClick(scope.row)" type="primary"
							size="mini">审核</el-button>
						<el-button @click="detail(scope.row)" type="primary"
							size="mini">审核流水</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div class="pagebox">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="totalRow">
				</el-pagination>
			</div>
		</div>

		<el-dialog title="问题处理" :close-on-click-modal="false" :visible.sync="problemDealVisible" width="800px">
			<el-form :model="form" :rules="rules" ref="form">
				<el-form-item label="审核结果" :label-width="formLabelWidth" prop="status">
					<el-select v-model="form.status" clearable placeholder="审核结果">
						<el-option label="审核通过" value="1"></el-option>
						<el-option label="审核未通过" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="审核理由" :label-width="formLabelWidth" prop="reason">
					<el-input type="textarea" v-model="form.reason" placeholder="审核理由"></el-input>
				</el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('form')">取 消</el-button>
				<el-button type="primary" @click="sureProcess('form')">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="审核流水" :close-on-click-modal="false" :visible.sync="problemDealVisible2" width="1200px" :before-close="handleClose">
			<div class="searchbox">
				<el-form :inline="true" class="demo-form-inline">
					<el-form-item>
						<el-button type="primary" @click="search2"><i class="el-icon-refresh"></i> 刷新</el-button>
					</el-form-item>
				</el-form>
				<div class="contbox">
					<el-table :data="tableData2" :header-cell-style="{  fontSize: '14px', color: '#282828'}"
						:row-style="{ fontSize: '12px', color: '#6a6a6a'}" border style="width: 100%">
						<el-table-column align="center" prop="create_time" label="审核时间" width="160">
						</el-table-column>
						<el-table-column align="center" prop="name" label="审核人" width="160">
						</el-table-column>
						<el-table-column align="center" label="审核状态" width="160">
							<template slot-scope="scope">
								<span v-if="scope.row.status == 0">提交审核</span>
								<span v-if="scope.row.status == 1" style="color: green;">审核通过</span>
								<span v-if="scope.row.status == 2" style="color: red;">审核未通过</span>
							</template>
						</el-table-column>
						<el-table-column align="center" prop="reason" label="审核理由">
						</el-table-column>
					</el-table>
				
					<div class="pagebox">
						<el-pagination background @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
							:current-page="currentPage2" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize2"
							layout="total, sizes, prev, pager, next, jumper" :total="totalRow2">
						</el-pagination>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		changePeriodPage,
		changePeriodAudit,
		auditLogPage
	} from '@/api/ninesiteproblem'
	import {
		periodOptions
	} from '../patrolTasks/dict'

	export default {
		name: "",
		props: {},
		data() {
			return {
				formInline: {
					gridman: '',
					address:'',
					alarmStatus:'',
					status: '',
					showname:''
				},
				auditid:'',
				problemDealVisible2:false,
				tableData: [],
				tableData2:[],
				currentPage: 1,
				pageSize: 10,
				currentPage2: 1,
				pageSize2: 10,
				totalRow2: 0,
				totalRow:0,
				formLabelWidth: '120px',
				periodOptions,
				form: {
					auditid:'',
					status:'',
					reason:'',
				},
				problemDealVisible: false,
				rules: {
					status: [{
						required: true,
						message: '请选择审核结果',
						trigger: 'change'
					}],
					reason: [{
						required: true,
						message: '请填写审核理由',
						trigger: 'change'
					}],
				},
			}
		},
		watch: {},
		created() {},
		mounted() {
			this.getList();
		},
		computed: {},
		methods: {
			detail(a){
				let that = this;
				that.auditid = a.auditid;
				that.problemDealVisible2 = true;
				that.getList2();
			},
			checkSelectSet(row, index) {
				if (row.problem_status == 0) {
					return true;
				} else {
					return false;
				}
			},
			handleClose: function(done) {
				this.currentPage2 = 1;
				this.tableData2 = [];
				this.auditid = '';
				done();
			},
			//巡防任务列表
			getList2() {
				auditLogPage({
					auditid:this.auditid,
					page: this.currentPage2,
					pageSize: this.pageSize2
				}).then(res => {
					if (res.result == 200) {
						this.tableData2 = res.detail.list;
						this.totalRow2 = res.detail.totalRow;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			handleSizeChange2(val) {
				this.pageSize2 = val;
				this.getList2();
			},
			handleCurrentChange2(val) {
				this.currentPage2 = val;
				this.getList2();
			},
			//查询
			search2() {
				this.currentPage2 = 1;
				this.getList2();
			},
			//巡防任务列表
			getList() {
				changePeriodPage({
					gridman: this.formInline.gridman,
					address: this.formInline.address,
					alarmStatus: this.formInline.alarmStatus,
					status: this.formInline.status,
					showname: this.formInline.showname,
					page: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					if (res.result == 200) {
						this.tableData = res.detail.list;
						this.totalRow = res.detail.totalRow;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			//查询
			search() {
				this.currentPage = 1;
				this.getList();
			},
			
			//处理
			dealClick(row) {
				this.form.auditid = row.auditid;
				this.problemDealVisible = true;
			},
			/**
			 * 字段格式化
			 * @param {Number|String} value
			 * @param {Array} list
			 * @return {String}
			 */
			formatterStatus(value, list) {
				if (value === "" || value == undefined) return "";
				const item = list.find((item) => item.value == value);
				return item ? item.label : "";
			},
			//确认提交处理
			sureProcess(formName) {
				let that = this;
				that.$refs[formName].validate((valid) => {
					if (valid) {
						changePeriodAudit({
							auditid: that.form.auditid,
							status: that.form.status,
							reason: that.form.reason,
						}).then(res => {
							if (res.result == 200) {
								console.log(res)
								that.$message.success('操作成功');
								that.resetForm('form');
								that.search();
							} else {
								that.$message.warning(res.description)
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.problemDealVisible = false;
			}

		}
	}
</script>

<style lang="scss" scoped>
	.contbox {

		.totalinfo {
			padding: 10px 10px 8px;
			display: flex;

			>div {
				margin-right: 30px;
				font-weight: bold;
				color: #333;

				span {
					font-weight: normal;
					color: #409eff;
					font-size: 16px;
				}
			}
		}

		.pagebox {
			padding: 20px 0;
		}
	}

	.problemdesc {
		min-height: 40px;
		line-height: 1.6em;
		background: #f5f5f5;
		padding: 20px 0 5px;
		margin-bottom: 20px;

		>div {
			display: flex;
			margin-bottom: 10px;

			.filed {
				padding: 0 10px;
				width: 120px;
				text-align: right;
				box-sizing: border-box;
			}
		}

		.imgs {
			display: flex;
			margin-bottom: 20px;

			img {
				height: 90px;
				width: auto;
				margin-right: 10px;
			}
		}
	}

	.formbox {
		width: 94%;
	}
</style>